<template>
  <div class='wrapper'>
    <el-form :model="formInline" ref='form' :rules="rules" label-width="100px" class='ruleForm'
             v-for='(rule, index) in formInline.rules' :key='index'>
      <div class='splitLine' v-show='index'></div>
      <el-form-item label="生效日期:" :prop='"rules[" + index + "].date"' :rules='dateRules' label-width="166px">
        <el-date-picker
          v-model="rule.date"
          :editable=false
          :clearable=false
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :picker-options="pickerOptions0"
          :disabled="rule.dis"
        >
        </el-date-picker>
        <span v-if='!index' @click='addRule(index)' style='float:right' class='addEffictDate'>添加生效日期</span>
        <span v-if='index && !rule.dis' class='iconfont icon-shanchu-copy' style='float:right' @click='removeRule(rule)'
              :style="{'border-color':'red', 'color':'red', 'font-size': '35px'}"></span>
      </el-form-item>
      <!--<div style='padding-left:30px'>-->
        <!--<div style="border: 0" class='workdayWrapper'>-->
          <!--<div class='ruleContent'>-->
            <!--<el-form-item label="普通车免费分钟数" label-width="170px" :prop='"rules[" + index + "].freeTime"'-->
                          <!--:rules='freeTimeRule' :disabled='false'>-->
              <!--<el-input placeholder="普通车免费分钟数" v-model.number="rule.freeTime" style='width:200px'></el-input>-->
            <!--</el-form-item>-->
            <!--<el-form-item label="新能源免费分钟数" label-width="170px" :prop='"rules[" + index + "].ecFreeTime"'-->
                          <!--:rules='freeTimeRule' :disabled='false'>-->
              <!--<el-input placeholder="新能源免费分钟数" v-model.number="rule.ecFreeTime" style='width:200px'></el-input>-->
              <!--<span style='color: rgb(154,154,154);margin-left:5px'>注：每天首次生效</span>-->
            <!--</el-form-item>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <div style='padding-left:30px'>
        <div style="float:left;line-height:35px;width:124px;text-align: right;padding-right: 12px">
          工作日计费规则:
        </div>
        <div class='workdayWrapper'>
          <div class='ruleContent'>
            <day-rule ref='dayRule' :index="index" v-model="rule.workday" :dis='rule.dis' :typeId="formInline.id" @intervalData="intervalData"></day-rule>
          </div>
        </div>
        <div style='clear:both'></div>
      </div>
      <div style='padding-left:30px;margin:30px 0'>
        <div style="float:left;line-height:35px;width:124px;text-align: right;padding-right: 12px">
          非工作日计费规则:
        </div>
        <div>
          <el-select :disabled='rule.dis' v-model="rule.isConsistance">
            <el-option label="同工作日计费规则" :value="1"></el-option>
            <el-option v-if="rule.id && rule.id != 100" label="自定义" :value="0"></el-option>
          </el-select>
        </div>
        <div style='clear:both'></div>
      </div>
      <div style='padding-left:30px' v-if='!rule.isConsistance'>
        <div style="float:left;line-height:35px;width:150px;height:1px">
        </div>
        <div class='workdayWrapper'>
          <div class='ruleContent'>
            <day-rule ref='weekendRule' v-model="rule.weekend" :dis='rule.dis'></day-rule>
          </div>
        </div>
        <div style='clear:both'></div>
      </div>
    </el-form>
  </div>
</template>
<script>
  import dayRule from './dayRule'
  // import { valid } from 'semver';

  export default {
    data() {
      let checkDate = (rule, value, callback) => {
        if (!value) {
          callback(new Error('日期不能为空'));
        }
        let ind = null;
        let checkNum = this.formInline.rules.filter((v, index) => {
          if (v.date == value) {
            ind = index
            return v
          }
        })
        if (checkNum.length > 1) {
          return callback(new Error('不能设置相同的日期'));
        }
        let curDate = value;
        let arr = [...this.formInline.rules];
        let tem = arr.splice(ind + 1);
        // 当前日期大于后面日期
        let col = tem.filter((value, index) => {
          return new Date(curDate) - new Date(value.date) >= 0;
        })
        if (col.length) {
          return callback(new Error('日期必须小于后面的日期！'));
        }
        arr = [...this.formInline.rules];
        tem = arr.splice(0, ind);
        col = tem.filter((value, index) => {
          return new Date(curDate) - new Date(value.date) <= 0;
        })
        if (col.length) {
          return callback(new Error('日期必须大于前面的日期！'));
        }
        callback()
      }
      let freeTimeRule = (rule, value, callback) => {
        let str = value + ''
        if (str.indexOf('.') != -1) {
          callback(new Error('不能为小数'));
        } else {
          callback()
        }
      }
      return {
        formInline: {},
        rules: {},
        pickerOptions0: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
        dateRules: [
          { required: true, message: '选择生效日期', trigger: 'blur' },
          { validator: checkDate, trigger: 'blur' }
        ],
        freeTimeRule: [
          { required: true, message: '不能为空', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
          { type: 'number', message: '不能小于0', min: 0, trigger: 'blur' },
          { type: 'number', message: '不能大于1440', max: 1440, trigger: 'blur' },
          { validator: freeTimeRule, trigger: 'blur' }
        ]
      }
    },
    props: ['value'],
    watch: {
      value: {
        handler(newVal, oldVal) {
          // if (this.intervalType == 100) {
          //   newVal.id = this.intervalType;
          // }
          newVal.rules.forEach(val => {
            if (!val.id) {
              val.id = newVal.id;
            }
          })
          this.formInline = newVal;
        },
        immediate: true
      },
      formInline: {
        handler(newVal, oldVal) {
          // if (this.intervalType == 100) {
          //   this.formInline.id = this.intervalType;
          // } else {
          //   this.intervalType = this.formInline.id;
          // }
          // if (!newVal.id) {
          //   this.intervalType = newVal.id;
          // }
          this.$emit('input', newVal)
        },
        deep: true
      }
    },
    components: {
      dayRule
    },
    methods: {
      intervalData(val, index) {
        this.formInline.id = val;
        this.formInline.rules[index].id = val;
        // this.formInline.rules[index].weekend = 
      },
      setScroll() {
        this.$nextTick(() => {
          let doc = document.getElementById('content');
          doc.scrollTop = doc.scrollHeight - doc.clientHeight
        })
      },
      removeRule(item) {
        var index = this.formInline.rules.indexOf(item)
        if (index !== -1) {
          this.formInline.rules.splice(index, 1)
        }
      },
      addRule(index) {
        this.formInline.rules.push({
          "date": "",
          "freeTime": 15,
          "ecFreeTime": 0,
          "isConsistance": 1,
          "id": 103,
          "workday": {
            "start": "",
            "end": "",
            "limit": "0",
            "id": 103,
            "items": [{
              "id": 103,
              "period": 0,
              "unit": "0"
            }]
          }, 
          "weekend": {
            "start": "",
            "end": "",
            "limit": "0",
            "id": 103,
            "items": [{
              "id": 103,
              "period": 0,
              "unit": "0"
            }]
          }
        });
        this.setScroll()
      },
      checkChildForm(ref) {
        let forms = this.$refs[ref]
        let flag = true;
        if (!forms) return flag;
        if (Array.isArray(forms)) {
          forms.forEach(form => {
            form.validate((valid) => {
              if (!valid) {
                flag = false
              }
            })
          })
        } else {
          forms.validate((valid) => {
            if (!valid) {
              flag = false
            }
          })
        }
        // this.$refs.form.validate(fn);
        return flag
      },
      checkForm(fn) {
        let flag = true;
        if (!this.checkChildForm('form')) {
          flag = false;
        };
        if (!this.checkChildForm('dayRule')) {
          flag = false;
        };
        if (!this.checkChildForm('weekendRule')) {
          flag = false;
        };
        fn && fn(flag)
        return flag
      }
    }
  }
</script>
<style scoped>
  .wrapper {
    padding: 10px;
    /* border: 1px solid #c0ccda; */
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .addEffictDate {
    display: inline-block;
    line-height: 30px;
    cursor: pointer;
  }

  .addEffictDate:hover {
    color: blue;
  }

  .workdayWrapper {
    overflow: hidden;
    border: 1px solid #e1e1e1;
    padding: 15px;
    border-radius: 5px;
  }

  .ruleContent {
    width: 900px;
    margin: 0 auto;
  }

  .ruleForm {
    margin-bottom: 50px;
  }

  .splitLine {
    border-top: 1px dashed #666;
    margin-bottom: 50px;
  }
</style>

