<template>
  <div>
    <el-form
      :model="item"
      :ref="'form'"
      label-width="100px"
      :inline="true"
      :rules="rules"
      v-for="(item, index) in formInline"
      :key="index"
    >
      <div
        style="
          float: left;
          line-height: 35px;
          padding: 4px;
          width: 160px;
          text-align: right;
        "
      >
        区间{{ index + 1 }}
      </div>
      <div style="float: left; margin-left: 5px" v-if="item.id == 103">
        <el-form-item prop="period">
          <el-input
            :disabled="dis"
            v-model.number="item.period"
            style="width: 110px"
            placeholder="区间分钟数"
            :maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item prop="unit">
          <el-input
            :disabled="dis"
            v-model="item.unit"
            style="width: 110px"
            placeholder="区间单价"
            :maxlength="10"
          ></el-input>
        </el-form-item>
        <span style="vertical-align: middle; line-height: 40px">元/30分钟</span>
        <span
          style="line-height: 40px; vertical-align: middle"
          v-if="!dis"
          @click="itemOpreate(index, item)"
          :class="index ? 'iconfont icon-shanchu-copy' : 'iconfont icon-jia'"
          :style="{
            'border-color': index ? 'red' : 'blue',
            color: index ? 'red' : 'blue',
            'font-size': '30px'
          }"
        ></span>
      </div>
      <div style="float: left; margin-left: 5px" class="count-wrap" v-if="item.id == 100">
        <el-form-item>
          <el-input
            placeholder="区间单价"
            v-model.trim="item.unit"
            type="number"
            :min="0"
            :disabled="dis"
            style="width: 110px"
            :onkeyup="
              (item.unit = item.unit.replace(
                /[^\d]/g,
                ''
              ))
            "
          ></el-input>
          <span style="vertical-align: middle; line-height: 40px">元/次</span>
        </el-form-item>
      </div>
      <div style="clear: both"></div>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    let checkUnit = (rule, value, callback) => {
      if (value < 0) {
        return callback(new Error("不能小于0"));
      } else if (!/^[\d.]{1,}$/.test(value)) {
        return callback(new Error("必须为数字"));
      }
      callback();
    };
    let checkPeriod = (rule, value, callback) => {
      if (value <= 0) {
        return callback(new Error("不能小于等于0"));
      } else if (!/^[\d.]{1,}$/.test(value)) {
        return callback(new Error("必须为数字"));
      } else if (value % 30 != 0) {
        return callback(new Error("必须为30的倍数"));
      }
      callback();
    };
    return {
      formInline: {},
      rules: {
        period: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur"
          },
          { type: "number", message: "必须为数字值", trigger: "blur" },
          { validator: checkPeriod, trigger: "blur" }
        ],
        unit: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur"
          },
          { validator: checkUnit, trigger: "blur" }
        ]
      }
    };
  },
  props: ["value", "dis", "interval"],
  watch: {
    interval: {
      handler(newVal, oldVal) {
      },
      immediate: true
    },
    value: {
      handler(newVal, oldVal) {
        this.formInline = newVal;
        this.formInline.forEach(val => {
          if (!val.id) {
            val.id = this.interval;
          }
        })
      },
      immediate: true
    },
    formInline: {
      handler(newVal, oldVal) {
        this.$emit("input", newVal);
      },
      deep: true
    }
  },
  methods: {
    itemOpreate(index, item) {
      if (this.dis) {
        return;
      }
      if (!index) {
        this.formInline.push({
          id: 103,
          period: 0,
          unit: "0"
        });
      } else {
        var ind = this.formInline.indexOf(item);
        if (ind !== -1) {
          this.formInline.splice(ind, 1);
        }
      }
    },
    checkForm() {
      let forms = this.$refs.form;
      let flag = true;
      if (Array.isArray(forms)) {
        forms.forEach((form) => {
          form.validate((valid) => {
            if (!valid) {
              flag = false;
            }
          });
        });
      } else {
        forms.validate((valid) => {
          if (!valid) {
            flag = false;
          }
        });
      }
      // this.$refs.form.validate(fn);
      return flag;
    }
  }
};
</script>
<style scoped  lang="stylus" rel="stylesheet/stylus">
.count-wrap {
  /deep/ .el-form-item__error {
    display: none;
  }
  /deep/ .el-input__inner {
    border-color: #DCDFE6 !important;
  }
}
</style>

