<template>
  <div class='equipment' id='ruleWrapper'>
    <div class="breadcrumb"></div>
    <div class='e_content'>
      <div class='ruleMessage1'>
        <div class='title'>计费规则信息
          <i class="el-icon-question" style="font-size: 14px" @mouseover="ruleDefault=true" @mouseout="ruleDefault=false"></i>
          <transition name="slide-fade">
            <div class="ruleDefault" @mouseover="ruleDefault=true" @mouseout="ruleDefault=false" v-show="ruleDefault">
              以下内容为系统默认计费规则，无需配置：<br/>
              &nbsp;&nbsp;（1）每个自然日为一个独立计费周期，计费周期内按对应收费时段收费，下一个计费周期重新计费；<br/>
              &nbsp;&nbsp;（2）停车后15分钟内免费停车。停放超过15分钟的，前15分钟纳入计费停车时长。有欠费的机动车不享受该免费政策；<br/>
              &nbsp;&nbsp;（3）同一车辆在同一路段连续发生2次及以上停放行为的，若相邻两次停放的时间间隔低于半小时，则延续前一次停车时长进行计费；<br/>
              &nbsp;&nbsp;（4）消防车、救护车、应急抢险车、军警车辆以及法律、法规规定应当免收机动车停放服务费的车辆使用临时泊位的，免于支付使用费。<br/>
              &nbsp;&nbsp;（5）残疾人机动轮椅车在残疾人机动轮椅车专用泊位停放的，免予支付使用费。若停放在非专用泊位上的，使用费按规定标准减半支付；<br/>
              &nbsp;&nbsp;（6）在每个计费周期的收费时段内，新能源汽车停放充电桩泊位并充电的，免予支付首次充电首一小时（含）的泊位使用费。新能源巡游出租车还可以享受在执行行政性事业性收费的非重点路段泊位每日停放三次、每次停车时长不超过（含）一小时的免费停车优惠。前述两类车辆，如停车时长超过一小时的，不再享受本款优惠政策，应当支付全部停车时长的泊位使用费。
            </div>
          </transition>
        </div>
        <div class='content'>
          <top-compont ref='topCon' v-model="formInline"></top-compont>
          <body-compont ref='bodyCon' v-model="formInline.source"></body-compont>
        </div>
      </div>
      <div style='margin:0 auto;width:250px;margin-bottom:20px;'>
        <el-button type="primary" @click="submitData()" :loading="loading">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </div>
    </div>

  </div>
</template>
<script>
  import topCompont from './ruleComponents/top'
  import bodyCompont from './ruleComponents/body'

  export default {
    data() {
      return {
        loading: false,
        ruleDefault: false,
        isEdit: false,
        isBind: null,
        formInline: {
          feeName: '',
          feeCode: '',
          freeTime: 0,
          description: '',
          operationId: "",
          source: {
            "id": 103,
            "rules": [{
              "date": "",
              "freeTime": 0,
              "ecFreeTime": 0,
              "isConsistance": 1,
              "workday": {
                "start": "",
                "end": "",
                "limit": "0",
                "items": [{
                  "period": 0,
                  "unit": "0"
                }]
              },
              "weekend": {
                "start": "",
                "end": "",
                "limit": "0",
                "items": [{
                  "period": 0,
                  "unit": "0"
                }]
              }
            }]
          }
        }
      }
    },
    methods: {
      checkIsEq(rule) {
        let workday = rule.workday;
        let weekend = rule.weekend;
        let isEq = true;
        for (let i in workday) {
          if (typeof workday[i] == 'object') {
            let workItems = workday[i];
            let weekendItems = weekend[i];
            for (let i in workItems) {
              // eslint-disable-next-line no-mixed-operators
              if (!weekendItems[i] || workItems[i].period != weekendItems[i].period && workItems[i].unit != weekendItems[i].unit) {
                isEq = false;
              }
            }
            for (let i in weekendItems) {
              // eslint-disable-next-line no-mixed-operators
              if (!workItems[i] || workItems[i].period != weekendItems[i].period && workItems[i].unit != weekendItems[i].unit) {
                isEq = false;
              }
            }
          } else if (workday[i] != weekend[i]) {
            isEq = false;
          }
        }
        return isEq;
      },
      handleData() {
        this.formInline.source.rules.forEach((rule, index) => {
          // rule.dis = this.isBind || false;
          // 根据生效日期判断disabled
          rule.dis = new Date(rule.date + ' 00:00:00').getTime() <= new Date().getTime()
          if (this.checkIsEq(rule)) {
            rule.isConsistance = 1;
          } else {
            rule.isConsistance = 0;
          }
        });
        this.formInline = JSON.parse(JSON.stringify(this.formInline))
      },
      checkData() {
        let flag = true;
        if (!this.$refs.bodyCon.checkForm()) {
          flag = false;
          console.log('bodyCon', flag)
        }
        if (!this.$refs.topCon.checkForm()) {
          flag = false;
          console.log('topCon', flag)
        }
        return flag;
      },
      beforeSend() {
        let data = JSON.parse(JSON.stringify(this.formInline));
        data.source.rules.forEach((rule, index) => {
          if (rule.isConsistance == 1) {
            rule.weekend = rule.workday;
          }
          delete rule.isConsistance;
          delete rule.dis;
        });
        return data
      },
      submitData() {
        if (this.checkData()) {
          let data = this.beforeSend();
          this.sendRequest(data)
          // if (this.isEdit) {
          //   this.$axios.get('/acb/2.0/feeHistory/existCreatedEditFeeHistory', {
          //     data: {
          //       feeId: this.$route.query.feeId
          //     }
          //   }).then(res => {
          //     if (res.state == 0) {
          //       if (res.value) {
          //         return this.$message.warning("已存在对应审核的计费规则配置！");
          //       } else {
          //         data.feeId = this.$route.query.feeId
          //         this.sendRequest(data)
          //       }
          //     }
          //   })
          // } else {
          //   this.sendRequest(data)
          // }
        }
      },
      sendRequest(data) {
        this.loading = true;
        data.source = JSON.stringify(data.source);
        // if (this.isEdit) {
        //   data.feeId = this.$route.query.feeId
        // }
        let method = this.isEdit ? 'post' : 'post';
        // let method = 'post'
        let url = !this.isEdit ? '/acb/2.0/fee/create' : '/acb/2.0/fee/update/' + this.$route.query.feeId;
        // let url = !this.isEdit ? '/acb/2.0/feeHistory/createAddFeeHistory' : '/acb/2.0/feeHistory/createEditFeeHistory';
        this.$axios[method](url, {
          data: data
        }).then(res => {
          this.loading = false;
          if (res.state == 0) {
            this.$message.success("提交成功")
            this.$router.go(-1);
          } else {
            //  this.getRuleHandle(JSON.parse(JSON.stringify(data2)), data2);
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        }).catch(() => {
          this.loading = false;
        })
      },
      getFeeData() {
        this.$axios.get('/acb/2.0/fee/byId/' + this.$route.query.feeId).then(res => {
          if (res.state == 0) {
            if (res.value.isBind) {
              this.isBind = true;
            }
            this.formInline.feeName = res.value.feeName;
            this.formInline.feeCode = res.value.feeCode;
            this.formInline.description = res.value.description ? res.value.description : '';
            this.formInline.operationId = res.value.operationId || '';
            this.formInline.source = JSON.parse(res.value.property);
            this.handleData();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        })
      }
    },
    components: {
      topCompont,
      bodyCompont
    },
    mounted() {
      if (this.$route.query.feeId) {
        this.isEdit = true;
        this.getFeeData();
      }
    }
  }
</script>
<style scoped>
  .equipment .e_content {
    border: 1px solid #c0ccda;
    border-radius: 4px;
    min-height: 550px;
    padding: 0 20px;
    color: #48576a;
  }

  .equipment .e_content .f-top {
    width: 700px;
    margin: 0 auto;
    margin-bottom: 80px;
  }

  .breadcrumb {
    height: 35px;
  }

  .ruleMessage {
    padding: 10px;
    /* border: 1px solid #c0ccda; */
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .title {
    line-height: 40px;
    padding-left: 20px;
  }

  .ruleMessage1 {
    position: relative;
  }

  .ruleDefault {
    position: absolute;
    left: 132px;
    top: 10px;
    width: 752px;
    background: #fff;
    padding: 10px;
    z-index: 2;
    box-shadow: 1px 0px 8px #c7c6c6;
    line-height: 24px;
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to{
    transform: translateX(10px);
    opacity: 0;
  }
</style>
