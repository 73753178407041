<template>
  <div>
    <el-form
      :model="formInline"
      :ref="'form'"
      :rules="rules"
      :inline="true"
      label-width="170px"
    >
      <div
        style="
          width: 162px;
          display: inline-block;
          line-height: 40px;
          text-align: right;
          padding-right: 8px;
        "
      >
        时段
      </div>
      <div v-if="!this.formInline.id" style="display: inline;">
        <!-- 基础时段时间段 -->
        <div v-if="interval == 103" style="display: inline;">
          <el-form-item prop="start">
            <el-time-select
              :disabled="dis"
              v-model="formInline.start"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              @change="timeHandle(formInline)"
              placeholder="选择时间"
            ></el-time-select>
            <!-- <el-time-picker :disabled='dis' value-format='HH:mm:ss'  v-model="formInline.start"  placeholder="任意时间点"   :picker-options='{format: "HH:mm"}' @change='timeHandle(formInline)'>  </el-time-picker> to  -->
          </el-form-item>
          <el-form-item prop="end">
            <el-time-select
              :disabled="dis"
              v-model="formInline.end"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              @change="timeHandle(formInline)"
              placeholder="选择时间"
            ></el-time-select>
            <!-- <el-time-picker :disabled='dis' value-format='HH:mm:ss' v-model="formInline.end"  placeholder="任意时间点"  :picker-options='{format: "HH:mm"}' @change='timeHandle(formInline)'>  </el-time-picker> -->
          </el-form-item>
        </div>
        <!-- 按次计费时间24小时 -->
        <div v-if="interval == 100" style="display: inline;">
          <el-form-item>
            <el-time-select
              :disabled="true"
              v-model="formInline.start"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="选择时间"
            ></el-time-select>
            <!-- <el-time-picker :disabled='dis' value-format='HH:mm:ss'  v-model="formInline.start"  placeholder="任意时间点"   :picker-options='{format: "HH:mm"}' @change='timeHandle(formInline)'>  </el-time-picker> to  -->
          </el-form-item>
          <el-form-item>
            <el-time-select
              :disabled="true"
              v-model="formInline.end"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="选择时间"
            ></el-time-select>
            <!-- <el-time-picker :disabled='dis' value-format='HH:mm:ss' v-model="formInline.end"  placeholder="任意时间点"  :picker-options='{format: "HH:mm"}' @change='timeHandle(formInline)'>  </el-time-picker> -->
          </el-form-item>
        </div>
        <el-form-item style="display: inline-block; padding-left: 175px">
          <el-select
            :disabled="dis"
            v-model="interval"
            placeholder="请选择"
            @change="selectInterval"
          >
            <el-option
              v-for="item in intervalList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div>
          <items-con
            :ref="'items'"
            v-model="formInline.items"
            :dis="dis"
            :interval="interval"
          ></items-con>
          <el-form-item
            class="timeMax"
            prop="limit"
            label="时段最高收费金额"
            label-width="170px"
            v-if="interval == 103"
          >
            <el-input
              :disabled="dis"
              placeholder="时段最高收费金额"
              :maxlength="50"
              v-model.trim="formInline.limit"
              style="width: 200px"
            ></el-input>
            “0”表示没有最高限额
          </el-form-item>
        </div>
      </div>
      <div v-else style="display: inline;">
        <!-- 基础时段时间段 -->
        <div v-if="formInline.id == 103" style="display: inline;">
          <el-form-item prop="start">
            <el-time-select
              :disabled="dis"
              v-model="formInline.start"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              @change="timeHandle(formInline)"
              placeholder="选择时间"
            ></el-time-select>
            <!-- <el-time-picker :disabled='dis' value-format='HH:mm:ss'  v-model="formInline.start"  placeholder="任意时间点"   :picker-options='{format: "HH:mm"}' @change='timeHandle(formInline)'>  </el-time-picker> to  -->
          </el-form-item>
          <el-form-item prop="end">
            <el-time-select
              :disabled="dis"
              v-model="formInline.end"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              @change="timeHandle(formInline)"
              placeholder="选择时间"
            ></el-time-select>
            <!-- <el-time-picker :disabled='dis' value-format='HH:mm:ss' v-model="formInline.end"  placeholder="任意时间点"  :picker-options='{format: "HH:mm"}' @change='timeHandle(formInline)'>  </el-time-picker> -->
          </el-form-item>
        </div>
        <!-- 按次计费时间24小时 -->
        <div v-if="formInline.id == 100" style="display: inline;">
          <el-form-item>
            <el-time-select
              :disabled="true"
              v-model="formInline.start"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="选择时间"
            ></el-time-select>
            <!-- <el-time-picker :disabled='dis' value-format='HH:mm:ss'  v-model="formInline.start"  placeholder="任意时间点"   :picker-options='{format: "HH:mm"}' @change='timeHandle(formInline)'>  </el-time-picker> to  -->
          </el-form-item>
          <el-form-item>
            <el-time-select
              :disabled="true"
              v-model="formInline.end"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }"
              placeholder="选择时间"
            ></el-time-select>
            <!-- <el-time-picker :disabled='dis' value-format='HH:mm:ss' v-model="formInline.end"  placeholder="任意时间点"  :picker-options='{format: "HH:mm"}' @change='timeHandle(formInline)'>  </el-time-picker> -->
          </el-form-item>
        </div>
        <el-form-item style="display: inline-block; padding-left: 175px">
        <el-select
          :disabled="dis"
          v-model="formInline.id"
          placeholder="请选择"
          @change="selectInterval"
        >
          <el-option
            v-for="item in intervalList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div>
        <items-con
          :ref="'items'"
          v-model="formInline.items"
          :dis="dis"
          :interval="interval"
        ></items-con>
        <el-form-item
          class="timeMax"
          prop="limit"
          label="时段最高收费金额"
          label-width="170px"
           v-if="formInline.id == 103"
        >
          <el-input
            :disabled="dis"
            placeholder="时段最高收费金额"
            :maxlength="50"
            v-model.trim="formInline.limit"
            style="width: 200px"
          ></el-input>
          “0”表示没有最高限额
        </el-form-item>
      </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import itemsCon from "./items";
export default {
  data() {
    let checkLimit = (rule, value, callback) => {
      if (value < 0) {
        return callback(new Error("不能小于0"));
      } else if (!/^[\d.]{1,}$/.test(value)) {
        return callback(new Error("必须为数字"));
      }
      callback();
    };
    let checkStartTime = (rule, value, callback) => {
      if (
        this.formInline.start &&
        this.formInline.end &&
        this.formInline.end != "00:00:00"
      ) {
        let timePeriod =
          (new Date("2018/08/01 " + this.formInline.end) -
            new Date("2018/08/01 " + this.formInline.start)) /
          (1000 * 60);
        if (timePeriod < 0 || this.formInline.start == this.formInline.end) {
          return callback(new Error("不能跨天设置时间！"));
        }
      }
      callback();
    };
    let checkEndTime = (rule, value, callback) => {
      if (
        this.formInline.start &&
        this.formInline.end &&
        this.formInline.end != "00:00:00"
      ) {
        let timePeriod =
          (new Date("2018/08/01 " + this.formInline.end) -
            new Date("2018/08/01 " + this.formInline.start)) /
          (1000 * 60);
        if (timePeriod < 0 || this.formInline.start == this.formInline.end) {
          return callback(new Error("不能跨天设置时间！"));
        }
      }
      callback();
    };
    return {
      formInline: {},
      rules: {
        start: [
          {
            required: true,
            message: "选择时间",
            trigger: "blur"
          },
          { validator: checkStartTime, trigger: "blur" }
        ],
        end: [
          {
            required: true,
            message: "选择时间",
            trigger: "blur"
          },
          { validator: checkEndTime, trigger: "blur" }
        ],
        limit: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur"
          },
          { validator: checkLimit, trigger: "blur" }
        ]
      },
      intervalList: [
        {
          value: 103,
          label: "基础时段"
        },
        // {
        //   value: 100,
        //   label: "按次计费"
        // }
      ],
      interval: 103
    };
  },
  props: ["value", "dis", "typeId", "index"],
  components: {
    itemsCon
  },
  watch: {
    typeId: {
      handler(newVal, oldVal) {
        this.interval = newVal;
      },
      deep: true
    },
    value: {
      handler(newVal, oldVal) {
        // if (!newVal.id) {
        //   this.formInline.id = 103;
        //   this.interval = 103;
        // }
        if (newVal.id == 100) {
          this.formInline.id = 100;
          newVal.start = "00:00:00";
          newVal.end = "00:00:00";
        } else {
          newVal.start = newVal.start;
          newVal.end = newVal.end;
        }
        // newVal.items[0].id = newVal.id;
        this.formInline = newVal;
        this.$emit("input", newVal);
      },
      immediate: true
    },
    formInline: {
      handler(newVal, oldVal) {
        this.$emit("input", newVal);
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    /**
     * 时段选择
     */
    selectInterval(val) {
      if (val == 100) {
        this.$refs.form.resetFields();
        this.$refs.form.clearValidate();
        this.formInline.start = "00:00:00";
        this.formInline.end = "00:00:00";
        this.formInline.items = [{
          period: 0,
          unit: "0"
        }]
      } else {
        this.formInline.items = [{
          id: val,
          period: 0,
          unit: "0"
        }]
      }
      // this.$refs.items.checkForm();
      this.interval = val;
      this.formInline.id = val;
      this.formInline.items.forEach(element => {
        element.id = val;
      });
      this.$emit("intervalData", val, this.index);
    },
    checkTimePeriod() {
      let flag = true;
      let timePeriod =
        (new Date("2018/08/01 " + this.formInline.end) -
          new Date("2018/08/01 " + this.formInline.start)) /
        (1000 * 60);
      if (timePeriod == 0) {
        timePeriod = 24 * 60;
      }
      if (timePeriod < 0) {
        timePeriod += 1440;
      }
      let temPeriod = 0;
      this.formInline.items.forEach((v) => {
        temPeriod += v.period;
      });
      if (temPeriod != timePeriod) {
        flag = false;
        this.$alert("区间分钟数之和必须与时段之差相等！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine')
        });
      }
      return flag;
    },
    timeHandle(formInline) {
      // if (formInline.start && formInline.start.split(':')[2] != '00') {
      //     let tem = formInline.start.split(':');
      //     tem[2] = '00';
      //     formInline.start = tem.join(':');
      // }
      // if (formInline.end && formInline.end.split(':')[2] != '00') {
      //     let tem = formInline.end.split(':');
      //     tem[2] = '00';
      //     formInline.end = tem.join(':');
      // }
      if (formInline.start && formInline.start.split(":")[2] != "00") {
        formInline.start = formInline.start + ":00";
      }
      if (formInline.end && formInline.end.split(":")[2] != "00") {
        formInline.end = formInline.end + ":00";
      }
    },
    checkChildForm(ref) {
      let forms = this.$refs[ref];
      let flag = true;
      if (Array.isArray(forms)) {
        forms.forEach((form) => {
          form.validate((valid) => {
            if (!valid) {
              flag = false;
            }
          });
        });
      } else {
        forms.validate((valid) => {
          if (!valid) {
            flag = false;
          }
        });
      }
      // this.$refs.form.validate(fn)
      return flag;
    },
    validate(fn) {
      let flag = true;
      if (!this.checkChildForm("form")) {
        flag = false;
      }
      if (this.formInline.id != 100) {
        if (!this.$refs.items.checkForm()) {
          flag = false;
        }
        if (!this.checkTimePeriod()) {
          flag = false;
        }
      }

      fn && fn(flag);
      return flag;
    }
  }
};
</script>
<style scoped>
.help {
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  color: white;
  width: 20px;
  line-height: 20px;
  vertical-align: middle;
  background: #c0ccda;
  position: relative;
}
</style>

